<template>
  <div>
    <b-container fluid class="content">
      <b-row>
        <b-col>
          <b-card>
            <b-form>
              <el-row :gutter="10">
                <el-col :span="4">
                  <base-input addon-left-icon="ni ni-calendar-grid-58">
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      :placeholder="$t('Start Date')"
                      @on-open="focus"
                      @on-close="blur"
                      :config="picker_config"
                      class="form-control datepicker"
                      v-model="cur_start_dt"
                    >
                    </flat-picker>
                  </base-input>
                </el-col>
                <el-col :span="4">
                  <base-input addon-left-icon="ni ni-calendar-grid-58">
                    <flat-picker
                      slot-scope="{ focus, blur }"
                      :placeholder="$t('End Date')"
                      @on-open="focus"
                      @on-close="blur"
                      :config="picker_config"
                      class="form-control datepicker"
                      v-model="cur_end_dt"
                    >
                    </flat-picker>
                  </base-input>
                </el-col>
                <el-col :span="4">
                  <select class="form-control" v-model="cur_CustomerIDn">
                    <option :value="0">{{ $t("Select Customer") }}</option>
                    <option
                      v-for="(dt, idx) in customerlist"
                      :key="idx"
                      :value="dt.IDn"
                    >
                      {{ dt.CompanyName }} - {{ dt.OtherName }}
                    </option>
                  </select>
                </el-col>
                <el-col :span="4" :offset="4">
                  <el-button
                    type="primary"
                    class="mt-2"
                    icon="el-icon-search"
                    size="small"
                    @click="get_outbound_list"
                    >{{ $t("Search") }}</el-button
                  >
                </el-col>
                <el-col :span="4">
                  <el-button
                    type="primary"
                    class="mt-2"
                    icon="el-icon-plus"
                    size="small"
                    @click="add_outbound"
                    v-acl:OutboundEdit.enable.show
                    >{{ $t("Add") }}</el-button
                  >
                </el-col>
              </el-row>
            </b-form>
          </b-card>
          <b-card>
            <div></div>
            <p class="p-3" v-if="outboundlist">
              <el-table
                stripe
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="outboundlist"
                @row-click="row_clicked_event"
              >
                <el-table-column
                  :label="$t('Location')"
                  prop="LocationName"
                  width="200px"
                >
                </el-table-column>
                <el-table-column
                  :label="$t('Outbound')+' #'"
                  prop="OutboundNo"
                ></el-table-column>
                <el-table-column
                  :label="$t('Outbound Date')"
                  prop="OutboundDate"
                ></el-table-column>
                <el-table-column
                  :label="$t('Order')+' #'"
                >
                  <template v-slot="{ row }">
                    {{ 
                      row.OrderNo_2 ?
                      row.OrderNo_1 + "(" + row.OrderNo_2 + ")" :
                      row.OrderNo_1
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Sales')"
                >
                  <template v-slot="{ row }">
                    {{ row.OutboundBy > 0 ?
                      usersidxlist[row.OutboundBy]["UserName"] :
                      ""
                    }}
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('Status')"
                  prop="Status"
                ></el-table-column>
                <el-table-column
                  :label="$t('Remarks')"
                  prop="Remarks"
                ></el-table-column>
              </el-table>
              <b-pagination
                v-model="current_page"
                :total-rows="outbound_total"
                :per-page="per_page"
              ></b-pagination>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col> </b-col>
      </b-row>
    </b-container>
    <b-modal
      ref="my-modal"
      :title="$t('Outbound Detail')"
      id="outbound_modal"
      size="xl"
      @hidden="detail_hidden"
    >
      <form v-if="cur_order">
        <el-row :gutter="10">
          <el-col :span="6">
            <b-input-group :prepend="$t('Customer') + ' : '">
              <select
                class="form-control"
                v-model="cur_order.CustomerIDn"
                :disabled="cur_order.Status == 'Draft' ? false : true"
                @change="customer_change"
              >
                <option :value="0"></option>
                <option
                  v-for="(dt, idx) in customerlist"
                  :key="idx"
                  :value="dt.IDn"
                >
                  {{ dt.CompanyName }} - {{ dt.OtherName }}
                </option>
              </select>
            </b-input-group>
          </el-col>
          <el-col :span="6">
            <b-input-group :prepend="$t('Store') + ' : '">
              <select
                class="form-control"
                v-model="cur_order_location_id"
                :disabled="cur_order.Status == 'Draft' ? false : true"
                @change="store_change"
              >
                <option value=""></option>
                <option
                  v-for="(dt, idx) in storelist"
                  :key="idx"
                  :value="dt.LocationID"
                >
                  {{ dt.LocationName }} - {{ dt.LocationID }}
                </option>
              </select>
            </b-input-group>
          </el-col>
          <el-col :span="6">
            <b-input-group :prepend="$t('Outbound')+' # : '">
              <b-form-input v-model="cur_order.OutboundNo" disabled></b-form-input>
            </b-input-group>
          </el-col>
          <el-col :span="6">
            <b-input-group :prepend="$t('Order')+' #: '">
              <b-form-input
                :value="
                  cur_order.OrderNo_2 ?
                  cur_order.OrderNo_1 + '(' + cur_order.OrderNo_2 + ')' :
                  cur_order.OrderNo_1
                "
                disabled
              ></b-form-input>
            </b-input-group>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="6">
            <b-input-group :prepend="$t('Date')+' : '">
              <flat-picker
                :config="picker_config2"
                class="form-control datepicker"
                v-model="cur_order.OutboundDate"
                :disabled="cur_order.Status == 'Draft' ? false : true"
              >
              </flat-picker>
            </b-input-group>
          </el-col>
          <el-col :span="18">
            <b-input-group :prepend="$t('Remarks')+' : '">
              <b-form-input
                v-model="cur_order.Remarks"
                :disabled="cur_order.Status == 'Draft' ? false : true"
              ></b-form-input>
            </b-input-group>
          </el-col>
        </el-row>
        <hr />
        <el-row :gutter="5" v-if="cur_order.Status == 'Draft'">
          <el-col :span="6">
            <b-input-group :prepend="'PLU/'+$t('Barcode')+'/'+$t('Name')+': '">
              <b-form-input
                v-model="cur_search_input"
                @keyup="delay_search_product"
              ></b-form-input>
            </b-input-group>
          </el-col>
          <el-col :span="6">
            <b-input-group :prepend="$t('Department')+': '">
              <select
                class="form-control"
                v-model="cur_search_departmentIDn"
                @change="changed_department"
              >
                <option :value="0"></option>
                <option
                  v-for="(dt, idx) in idxproductlist"
                  :key="idx"
                  :value="dt.IDn"
                >
                  {{ dt.Name1 }} - {{ dt.Name2 }}
                </option>
              </select>
            </b-input-group>
          </el-col>
          <el-col :span="6">
            <b-input-group :prepend="$t('Category')+': '">
              <select
                class="form-control"
                v-model="cur_search_categoryIDn"
                @change="changed_category"
              >
                <option :value="0"></option>
                <option
                  v-for="(dt, idx) in cur_searched_categorys"
                  :key="idx"
                  :value="dt.IDn"
                >
                  {{ dt.Name1 }} - {{ dt.Name2 }}
                </option>
              </select>
            </b-input-group>
          </el-col>
        </el-row>
        <section
          v-if="cur_listed_products.length > 0 && show_listed_products"
          class="outbound_product_section"
        >
          <el-table
            stripe
            class="table-responsive table"
            style="width: 100%"
            header-row-class-name="thead-light"
            :data="cur_listed_products"
            @row-click="add_order_product"
          >
            <el-table-column
              min-width="10%"
              label="PLU"
              prop="PLU"
            ></el-table-column>
            <el-table-column
              min-width="20%"
              :label="$t('Name')+'1'"
              prop="Name1"
            ></el-table-column>
            <el-table-column
              min-width="20%"
              :label="$t('Name')+'2'"
              prop="Name2"
            ></el-table-column>
            <el-table-column
              min-width="10%"
              :label="$t('Barcode')"
              prop="BarCode"
            ></el-table-column>
            <el-table-column
              min-width="5%"
              :label="$t('Unit')"
              prop="Unit"
            ></el-table-column>
            <el-table-column
              min-width="25%"
              :label="$t('Remarks')"
              prop="Remarks"
            ></el-table-column>
            <el-table-column min-width="10%" label="">
              <template slot="header">
                <base-button
                  type="primary"
                  size="sm"
                  v-if="show_listed_products"
                  class="mt-1"
                  @click="show_listed_products = false"
                  >{{ $t("Hide") }}</base-button
                >
              </template>
              <template>
                <base-button type="primary" size="sm" icon>
                  <span class="btn-inner--icon"
                    ><i class="ni ni-fat-add"></i
                  ></span>
                  <span class="btn-inner--text"></span>
                </base-button>
              </template>
            </el-table-column>
          </el-table>
        </section>
        <hr />
        <!-- product search and add -->
        <el-row :gutter="5">
          <el-col :span="4">
            <div class="product_head">PLU</div>
          </el-col>
          <el-col :span="6">
            <div class="product_head">{{ $t("Product Name") }}</div>
          </el-col>
          <el-col :span="3">
            <div class="product_head">{{ $t("Cost") }}</div>
          </el-col>
          <el-col :span="3">
            <div class="product_head">{{ $t("Qty") }}</div>
          </el-col>
          <el-col :span="3">
            <div class="product_head">{{ $t("Unit") }}</div>
          </el-col>
          <el-col :span="3">
            <div class="product_head">{{ $t("Subtotal") }}</div>
          </el-col>
          <el-col :span="2">
            <div class="product_head">{{ "S/N" }}</div>
          </el-col>
        </el-row>
        <el-row
          :gutter="5"
          v-for="(prod, idx) in computed_products"
          :key="idx"
          class="product_row"
        >
          <el-col :span="4">
            <div>{{ prod.PLU }}</div>
          </el-col>
          <el-col :span="6">
            <div>{{ prod.Name1 }} - {{ prod.Name2 }}</div>
          </el-col>
          <el-col :span="3">
            <div 
              v-if="cur_order.Status == 'Draft'" 
              class="el-input el-input--mini"
            >
              <input
                type="text"
                autocomplete="off"
                class="el-input__inner"
                v-model="prod.UnitPrice"
                @keyup="price_change($event, prod)"
              />
            </div>
            <div v-else>{{ prod.UnitPrice }}</div>
          </el-col>
          <el-col :span="3">
            <div
              v-if="cur_order.Status == 'Draft'"
              class="el-input el-input--mini"
            >
              <input
                type="text"
                autocomplete="off"
                class="el-input__inner"
                v-model="prod.OrderQty"
                @keyup="qty_change($event, prod)"
              />
            </div>
            <div v-else>{{ prod.OrderQty }}</div>
          </el-col>
          <el-col :span="3">
            <div>{{ prod.Unit }}</div>
          </el-col>
          <el-col :span="3">
            <div>{{ prod.TotalPrice }}</div>
          </el-col>
          <el-col :span="2">
            <div v-if="prod.HasSerialNumber" @click="selectSN(prod)">
              <i class="el-icon-connection hover_hand red_color"></i>
            </div>
            <div v-else>
              {{ "-" }}
            </div>
          </el-col>
        </el-row>
        <el-row class="summary_row">
          <el-col :offset="8" :span="5">
            <div>{{ $t("Summary") + ":" }}</div>
          </el-col>
          <el-col :span="3">
            <div>{{ cur_order.summary_Qty ? cur_order.summary_Qty : "" }}</div>
          </el-col>
          <el-col :offset="3" :span="3">
            <div>{{ cur_order.summary_Total ? cur_order.summary_Total : "" }}</div>
          </el-col>
        </el-row>
      </form>
      <template #modal-footer>
        <button
          type="button"
          class="btn btn-danger"
          v-if="cur_order.IDn > 0 && cur_order.Status == 'Draft'"
          @click="form_submit('Delete')"
        >
          {{ $t("Delete") }}
        </button>
        <button
          type="button"
          class="btn btn-success"
          v-if="cur_order.Status == 'Draft'"
          @click="form_submit('Post')"
        >
          {{ $t("Post") }}
        </button>
        <button
          type="button"
          class="btn btn-primary"
          v-if="cur_order.Status == 'Draft'"
          @click="form_submit('Draft')"
        >
          {{ $t("Draft") }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_my_modal">
          {{ $t("Close") }}
        </button>
      </template>
    </b-modal>
    
    <!-- SN select dialogue -->
    <b-modal
      ref="select-sn-modal"
      :title="$t('Input S/N')"
      id="select-sn-modal"
      size="sm"
      @shown="sn_shown"
    >
      <div v-if="cur_prod && cur_prod.Status == 'Draft'">
        <el-row>
          <el-col :span="18">
            <b-input class="form-control" v-model="input_SN" @keyup.enter.native="searchSN"/>
          </el-col>
          <el-col :span="6">
            <el-button type="primary" icon="el-icon-plus" @click="searchSN" style="margin-left: 20px"></el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            id="selectSNTable"
            ref="selectSNTable"
            stripe
            class="table-responsive table product-table mt-2"
            style="width: 100%"
            height="300"
            header-row-class-name="thead-light"
            :data="display_SN_array"
            :empty-text="$t('No Records')"
            @select="selectSNChange"
            @select-all="selectSNAll"
          >
            <el-table-column type="selection" width="60">
            </el-table-column>
            <el-table-column :label="$t('Serial Number')">
              <template slot-scope="scope">
                {{ scope.row.SN }}
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row v-if="cur_prod">
          {{tmp_selected_sn.length}} / {{ parseInt(cur_prod.OrderQty) }}
        </el-row>
      </div>
      <div v-else>
        <el-row>
          <el-table
            stripe
            class="table-responsive table product-table mt-2"
            style="width: 100%"
            height="300"
            header-row-class-name="thead-light"
            :data="tmp_selected_sn"
            :empty-text="$t('No Records')"
          >
            <el-table-column :label="$t('Serial Number')">
              <template slot-scope="scope">
                {{ scope.row }}
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row v-if="cur_prod">
          {{tmp_selected_sn.length}} / {{ parseInt(cur_prod.OrderQty) }}
        </el-row>
      </div>
      <template #modal-footer>
        <button type="button" class="btn btn-primary" @click="submit_sn">
          {{ $t('Submit') }}
        </button>
        <button type="button" class="btn btn-secondary" @click="close_select_sn_model">
          {{ $t('Close') }}
        </button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  Collapse,
  CollapseItem,
  Tag,
  Row,
  Col,
} from "element-ui";
// import InputAutocomplete from "../InputAutocomplete";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Tag.name]: Tag,
    [Row.name]: Row,
    [Col.name]: Col,
    [Pagination.name]: Pagination,
    flatPicker,
    // InputAutocomplete,
  },
  data() {
    return {
      input_SN: "",
      display_SN_array: [],
      cur_prod: null,
      tmp_selected_sn: [],

      usersidxlist: null,
      cur_order: null,
      cur_order_location_id: null,
      cur_opt: null,
      cur_search_input: "",
      cur_search_departmentIDn: 0,
      cur_search_categoryIDn: 0,
      cur_searched_categorys: [],
      cur_searched_products: [],
      cur_listed_products: [],
      show_listed_products: true,
      delay_search_timer: null,

      cur_Products: [],

      new_order: {
        IDn: 0,
        CustomerIDn: 0,
        OutboundNo: "",
        LocationID: "",
        OrderType: "OB",
        OrderIDn_1: 0,
        OrderIDn_2: 0,
        OrderNo_1: "",
        OrderNo_2: "",
        SoldTTitle: "",
        SoldTContact: "",
        SoldTAddress: "",
        SoldTTEL: "",
        SoldTFAX: "",
        Terms: "",
        OutboundDate: "",
        OutboundBy: 0,
        SubTotal: 0,
        TotalTAX: 0,
        TotalAmount: 0,
        Status: "Draft",
        Remarks: "",
        // newSN: "",
      },
      outboundlist: null,
      productlist: null,
      brandlist: null,
      storelist: null,
      customerlist: null,

      cur_start_dt: 0,
      cur_end_dt: 0,
      cur_CustomerIDn: 0,
      cur_LocationID: "",
      cur_OutboundNo: "",
      cur_OutboundDate: "",
      cur_OrderNo_1: "",
      cur_OrderNo_2: "",
      cur_OutboundBy: 0,
      cur_Status: "",
      cur_Remarks: "",

      today: "",

      delay_timer: null,

      current_page: 1,
      outbound_total: 0,
      per_page: 20,

      picker_config: {
        allowInput: true,
        dateFormat: "Y-m-d",
        altFormat: "Y-m-d",
        maxDate: "today", // New Date();  YYYY-MM-DD HH:MM
      },
      picker_config2: {
        allowInput: true,
        dateFormat: "Y-m-d",
        altFormat: "Y-m-d",
      },
      backcolor1: {
        backgrondColor: "#ffff00",
      },
      backcolor2: {
        backgrondColor: "#ee00ee",
      },
      token: "",
      user_id: 0,
    };
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_outbound_list_delay();
    },
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
    computed_products: function () {
      let tmp = [];
      if (this.cur_Products) {
        for (let i = 0; i < this.cur_Products.length; i++) {
          if (this.cur_Products[i]["Status"] != "Delete") {
            tmp.push(this.cur_Products[i]);
          }
        }
      }
      return tmp;
    },
  },
  methods: {
    hasUtilityAuth,
    price_change(event, prod) {
      if (!Number(prod.UnitPrice) || prod.UnitPrice.startsWith('-')) {
        let chars = prod.UnitPrice.split('');
        for (let i = chars.length - 1; i >= 0; i--) {
          if (
            (
              !Number(chars[i])
              && chars[i] != '0' 
              && chars[i] != '.'
            )
            || chars[i] == '-'
          ) {
            chars.splice(i, 1);
          }
        }
        prod.UnitPrice = chars.join('');
      }
      if (prod.UnitPrice.split('.').length > 2) {
        prod.UnitPrice = prod.UnitPrice.split('.')[0] + '.' + prod.UnitPrice.split('.')[1];
      }
      if((prod.UnitPrice.split('.').length > 1 && prod.UnitPrice.split('.')[1].length > 2)) {
        prod.UnitPrice = parseFloat(prod.UnitPrice).toFixed(2);
      }
      this.calculate_recodes(prod);
    },
    qty_change(event, prod) {
      if (!Number(prod.OrderQty) || prod.OrderQty.startsWith('-')) {
        let chars = prod.OrderQty.split('');
        for (let i = chars.length - 1; i >= 0; i--) {
          if (
            (
              !Number(chars[i])
              && chars[i] != '0' 
              && chars[i] != '.'
            )
            || chars[i] == '-'
          ) {
            chars.splice(i, 1);
          }
        }
        prod.OrderQty = chars.join('');
      }
      if (prod.OrderQty.split('.').length > 2) {
        prod.OrderQty = prod.OrderQty.split('.')[0] + '.' + prod.OrderQty.split('.')[1];
      }
      if((prod.OrderQty.split('.').length > 1 && prod.OrderQty.split('.')[1].length > 2)) {
        prod.OrderQty = parseFloat(prod.OrderQty).toFixed(2);
      }
      this.calculate_recodes(prod);
    },
    customer_change(evt) {
      let i;
      for (i = 0; i < this.customerlist.length; i++) {
        if (this.customerlist[i].IDn == this.cur_order.CustomerIDn) break;
      }
      this.cur_order.SoldTTitle = 
        (this.customerlist[i].CompanyName ? this.customerlist[i].CompanyName : "") +
        (this.customerlist[i].OtherName ? "(" + this.customerlist[i].OtherName + ")" : "");
      this.cur_order.SoldTContact = (
        this.customerlist[i].ContactFirstName1 ? this.customerlist[i].ContactFirstName1 + (
          this.customerlist[i].ContactLastName1 ? " " + this.customerlist[i].ContactLastName1 : ""
        ) : (
          this.customerlist[i].ContactLastName1 ? this.customerlist[i].ContactLastName1 : ""
        )
      );
      this.cur_order.SoldTAddress = (this.customerlist[i].Address1 ? this.customerlist[i].Address1 : "");
      this.cur_order.SoldTTEL = (this.customerlist[i].TEL ? this.customerlist[i].TEL : "");
      this.cur_order.SoldTFAX = (this.customerlist[i].FAX ? this.customerlist[i].FAX : "");
      this.cur_order.Terms = (this.customerlist[i].PaymentTermIDn ? this.customerlist[i].PaymentTermIDn : 0);
    },
    detail_hidden() {
      this.cur_search_input = "";
      this.cur_search_departmentIDn = 0;
      this.cur_search_categoryIDn = 0;
      this.cur_searched_products = null;
    },
    add_outbound() {
      this.cur_order = JSON.parse(JSON.stringify(this.new_order));
      this.cur_order_location_id = this.cur_order.LocationID;
      this.cur_Products = [];
      this.apply_order_calculate();
      
      this.idxproductlist = [];
      this.cur_listed_products = [];
      this.show_listed_products = true;

      for (var i = 0; i < this.idxproductlist.length; i++) {
        for (var j = 0; j < this.idxproductlist[i].Categorys.length; j++) {
          for (
            var k = 0;
            k < this.idxproductlist[i].Categorys[j].Products.length;
            k++
          ) {
            this.cur_listed_products.push(
              this.idxproductlist[i].Categorys[j].Products[k]
            );
          }
        }
      }
      this.$refs["my-modal"].show();
    },
    close_my_modal() {
      this.$refs["my-modal"].hide();
    },
    apply_order_calculate() {
      let SubTotal = 0;
      let TotalTAX = 0;
      let summary_Qty = 0
      let summary_Total = 0
      for (let i = 0; i < this.cur_Products.length; i++) {
        let prod = this.cur_Products[i];
        if (prod.Status == "Delete") continue;
        if (parseFloat(prod.TotalPrice)) {
          SubTotal += parseFloat(prod.TotalPrice);
        }
        if (parseFloat(prod.OrderQty) && parseFloat(prod.TotalPrice)) {
          summary_Qty += parseFloat(prod.OrderQty);
          summary_Total += parseFloat(prod.TotalPrice);
        }
      }
      let TotalAmount = SubTotal + TotalTAX;
      this.cur_order.SubTotal = SubTotal.toFixed(2);
      this.cur_order.TotalTAX = TotalTAX.toFixed(2);
      this.cur_order.TotalAmount = TotalAmount.toFixed(2);
      this.cur_order["summary_Qty"] = summary_Qty.toFixed(2);
      this.cur_order["summary_Total"] = summary_Total.toFixed(2);
    },
    remove_product(prod) {
      for (var i = this.cur_Products.length - 1; i >= 0; i--) {
        if (this.cur_Products[i].ProductIDn == prod.ProductIDn) {
          this.cur_Products.splice(i, 1);
        }
      }
      this.apply_order_calculate();
    },
    calculate_recodes(prod) {
      prod.TotalCost = 0;
      prod.TotalPrice = 0;
      if (parseFloat(prod.UnitCost) && parseFloat(prod.OrderQty)) {
        prod.TotalCost = (
          parseFloat(prod.UnitCost) * parseFloat(prod.OrderQty)
        ).toFixed(2);
      }
      if (parseFloat(prod.UnitPrice) && parseFloat(prod.OrderQty)) {
        prod.TotalPrice = (
          parseFloat(prod.UnitPrice) * parseFloat(prod.OrderQty)
        ).toFixed(2);
      }
      this.apply_order_calculate();
    },
    add_order_product(row, column, event) {
      event.preventDefault();
      if (column.label != "") {
        return;
      }
      if (this.cur_Products.length > 0) {
        // Check Product existing
        for (var i = 0; i < this.cur_Products.length; i++) {
          if (this.cur_Products[i].ProductIDn == row.IDn) {
            // Find existed, no add
            return;
          }
        }
      }
      let func = function (that, data = null){
        let prod = {
          IDn: 0,
          key: 0, // ?
          OutboundIDn: that.cur_order.IDn,
          // even if values exist, could be changed during Draft status, and need to be updated all the way.
          OrderType: "OB",
          OrderIDn_1: 0,
          OrderIDn_2: 0,
          OrderNo_1: "",
          OrderNo_2: "",
          // if location is changed, these are supposed to be changed
          InventoryIDn: null,
          LocationID: that.cur_order.LocationID,
          //
          ProductIDn: row.IDn,
          Name1: row.Name1,
          Name2: row.Name2,
          PLU: row.PLU,
          Unit: row.Unit,
          HasSerialNumber: row.HasSerialNumber,
          UnitPrice: row.APrice,
          UnitCost: row.APrice,
          OrderQty: 1,
          TotalCost: row.APrice,
          TotalPrice: row.APrice,
          Status: "Draft", // Delete, Post, Draft. Change along with Order status
          Remarks: "",
          SerialNumber: [],
          ReferenceIDn: 0,
        };
        if (row.HasSerialNumber && data && data.SystemSN && data.SystemSN.length > 0) {
          prod["SystemSN"] = data.SystemSN;
        }
        that.calculate_recodes(prod);
        that.cur_Products.unshift(prod);
        that.apply_order_calculate();
      };

      if (row.HasSerialNumber && row.IDn) {
        this.get_system_sn(
          row.IDn, 
          this.cur_order.LocationID, 
          func);
      } else {
        func(this);
      }
    },
    delay_search_product() {
      if (this.delay_search_timer) {
        clearTimeout(this.delay_search_timer);
        this.delay_search_timer = null;
      }
      var that = this;
      this.delay_search_timer = setTimeout(() => {
        that.search_product();
      }, 400);
    },
    matching_product(prod) {
      if (prod.PLU.search(this.cur_search_input) >= 0) {
        return true;
      }
      if (prod.Barcode.search(this.cur_search_input) >= 0) {
        return true;
      }
      if (prod.Name1.search(this.cur_search_input) >= 0) {
        return true;
      }
      if (prod.Name2.search(this.cur_search_input) >= 0) {
        return true;
      }
      return false;
    },
    search_product() {
      this.cur_listed_products = [];
      this.show_listed_products = true;

      if (this.cur_searched_products == null || this.cur_searched_products.length <= 0) {
        if (this.cur_search_input.length < 2) {
          for (var i = 0; i < this.idxproductlist.length; i++) {
            for (var j = 0; j < this.idxproductlist[i].Categorys.length; j++) {
              for (
                var k = 0;
                k < this.idxproductlist[i].Categorys[j].Products.length;
                k++
              ) {
                this.cur_listed_products.push(
                  this.idxproductlist[i].Categorys[j].Products[k]
                );
              }
            }
          }
          return;
        } else {
          for (var i = 0; i < this.idxproductlist.length; i++) {
            for (var j = 0; j < this.idxproductlist[i].Categorys.length; j++) {
              for (
                var k = 0;
                k < this.idxproductlist[i].Categorys[j].Products.length;
                k++
              ) {
                if (
                  this.matching_product(
                    this.idxproductlist[i].Categorys[j].Products[k]
                  )
                ) {
                  this.cur_listed_products.push(
                    this.idxproductlist[i].Categorys[j].Products[k]
                  );
                }
              }
            }
          }
          return;
        }
      }
      if (this.cur_searched_products.length > 0) {
        if (this.cur_search_input.length < 2) {
          this.cur_listed_products = this.cur_searched_products.slice();
          return;
        } else {
          for (var i = 0; i < this.cur_searched_products.length; i++) {
            if (this.matching_product(this.cur_searched_products[i])) {
              this.cur_listed_products.push(this.cur_searched_products[i]);
            }
          }
          return;
        }
      } else {
        return;
      }
    },
    check_product_location(idxproductlist) {
      let prod_list = new Object();
      for (var i = 0; i < idxproductlist.length; i++) {
        for (var j = 0; j < idxproductlist[i].Categorys.length; j++) {
          for (var k = 0; k < idxproductlist[i].Categorys[j].Products.length; k++) {
            prod_list[idxproductlist[i].Categorys[j].Products[k]["IDn"]] = 
              idxproductlist[i].Categorys[j].Products[k];
          }
        }
      }

      let errMsg = "";
      let err_chk_inv = false;
      let err_chk_qty = false;
      let err_chk_sn = false;
      for (let i = 0; i < this.cur_Products.length; i++) {
        if (this.cur_Products[i]["Status"] == "Delete") continue;
        let idn = this.cur_Products[i]["ProductIDn"];
        if (!prod_list[idn] || !prod_list[idn]["InventoryIDn"]) {
          err_chk_inv = true;
          this.cur_Products[i]["pre_delete"] = 1;
        }
        if (prod_list[idn] && prod_list[idn]["InventoryIDn"] && (
            !prod_list[idn]["InventoryQty"] || prod_list[idn]["InventoryQty"] < this.cur_Products[i]["Qty"]
          )
        ) {
          err_chk_qty = true;
          this.cur_Products[i]["pre_delete"] = 1;
        }
        if (prod_list[idn] && prod_list[idn]["HasSerialNumber"]) {
          err_chk_sn = true;
          this.cur_Products[i]["pre_delete"] = 1;
        }
      }
      
      if (err_chk_inv) errMsg += "There are products not avalable for the From Location. \n";
      if (err_chk_qty) errMsg += "There are products not sufficient for the From Location. \n";
      if (err_chk_sn) errMsg += "There are products with Serial Number management. \n";
      if (errMsg) errMsg += "\nIf click \"OK\", you agree on deleting the products; or \"Cancel\", the From Location will be revised.";
      if (errMsg) {
        if (confirm(errMsg)) {
          for (let i = this.cur_Products.length - 1; i >= 0; i--) {
            if (this.cur_Products[i]["pre_delete"]) {
              if (this.cur_Products[i]["IDn"]) {
                this.cur_Products[i]["Status"] = "Delete";
                delete this.cur_Products[i]["pre_delete"];
              } else {
                this.cur_Products.splice(i, 1);
              }
            }
          }
        } else {
          for (let i = 0; i < this.cur_Products.length; i++) {
            if (this.cur_Products[i]["pre_delete"]) {
              delete this.cur_Products[i]["pre_delete"];
            }
          }
          return false;
        }
      }
      for (let i = 0; i < this.cur_Products.length; i++) {
        this.cur_Products[i]["LocationID"] = this.cur_order_location_id;
        this.cur_Products[i]["InventoryIDn"] = prod_list[this.cur_Products[i]["ProductIDn"]]["InventoryIDn"];
      }
      return true;
    },
    store_change() {
      this.get_store_product(this.cur_order_location_id, function(that, data) {
        let tmp = that.check_product_location(data.idxproducts);
        if (tmp) {
          that.idxproductlist = data.idxproducts;
          that.cur_order.LocationID = that.cur_order_location_id;
        } else {
          that.cur_order_location_id = that.cur_order.LocationID;
        }
        that.search_product();
        that.apply_order_calculate();
        that.show_listed_products = tmp ? true : false;
      });
    },
    search_department(dept) {
      return dept.IDn == this.cur_search_departmentIDn;
    },
    changed_department() {
      this.cur_searched_categorys = [];
      this.cur_search_categoryIDn = 0;
      if (!this.cur_search_departmentIDn) {
        this.changed_category();
        return;
      }
      let findout = this.idxproductlist.find(this.search_department);
      if (findout == undefined) {
        this.changed_category();
        return;
      }
      this.cur_searched_categorys = findout.Categorys;
      this.changed_category();
    },
    search_category(cate) {
      return cate.IDn == this.cur_search_categoryIDn;
    },
    changed_category() {
      this.cur_searched_products = [];
      if (!this.cur_search_categoryIDn) {
        if (!this.cur_search_departmentIDn) {
          this.cur_searched_products = null;
          this.search_product();
          return;
        }
        for (let i in this.cur_searched_categorys) {
          this.cur_searched_products.push(...this.cur_searched_categorys[i].Products);
        }
        this.search_product();
      }
      let findout = this.cur_searched_categorys.find(this.search_category);
      if (findout == undefined) {
        this.search_product();
        return;
      }
      this.cur_searched_products = findout.Products;
      this.search_product();
    },
    setinput_brand(val) {
      this.cur_brand = val;
    },
    row_clicked_event(row, column, event) {
      if (!hasUtilityAuth('OutboundEdit')) return;
      event.preventDefault();
      this.show_listed_products = false;

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("IDn", row.IDn);
      bodyFormData.append("LocationID", row.LocationID);
      bodyFormData.append("Status", row.Status);

      var that = this;

      axios({
        method: "post",
        url: "/Api/Web/Inventory/Outbound/product",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_order = JSON.parse(JSON.stringify(row));
              that.cur_order_location_id = that.cur_order.LocationID;
              that.idxproductlist = rt.data.idxproducts;
              that.cur_Products = rt.data.products;
              that.apply_order_calculate();
              that.$refs["my-modal"].show();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_system_sn(productidn, locationid, func = function() {}) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("ProductIDn", productidn);
      bodyFormData.append("LocationID", locationid);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory/Outbound/system_sn",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              func(that, rt.data);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_store_product(locationid, func = function() {}) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("LocationID", locationid);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory/Outbound/get_store_product",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              func(that, rt.data);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    check_product_sn() {
      for (var i=0; i<this.cur_Products.length; i++) {
        if (this.cur_Products[i].HasSerialNumber == 1) {
          if (!this.cur_Products[i].SerialNumber || (this.cur_Products[i].SerialNumber.length != parseInt(this.cur_Products[i].OrderQty))) {
            alert(this.cur_Products[i].Name1 + ": SN count isn't same as product qty");
            return false;
          }
        }
      }
      return true;
    },
    async form_submit(Status) {
      if (!this.cur_order.LocationID) {
        alert("Please choose Store");
        return;
      }
      if (Status == "Post") {
        if (!this.cur_Products || !this.cur_Products.length) {
          alert("Please add products");
          return;
        }
        for (let i = 0; i < this.cur_Products.length; i++) {
          let prod = this.cur_Products[i];
          if (prod.HasSerialNumber) {
            if (prod.SerialNumber) {
              if ( prod.SerialNumber.length != parseInt(prod.OrderQty)) {
                let name = (prod.Name1 ? prod.Name1 : "") + " - " +(prod.Name2 ? prod.Name2 : "");
                alert("Serial Number count not match (" + name + ")");
                return;
              }
            } else {
              let name = (prod.Name1 ? prod.Name1 : "") + " - " +(prod.Name2 ? prod.Name2 : "");
              alert("Serial Number is not added (" + name + ")");
              return;
            }
          }
        }
      }
      this.apply_order_calculate();

      this.cur_order.Status = Status;
      if (this.cur_order.IDn == 0) {
        this.cur_order.OutboundBy = this.user_id;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      for (var key in this.cur_order) {
        if (this.cur_order[key]) {
          bodyFormData.append(key, this.cur_order[key]);
        }
      }
      bodyFormData.append("Products", JSON.stringify(this.cur_Products));

      if (this.cur_start_dt) {
        bodyFormData.append("StartDate", this.cur_start_dt);
      }
      if (this.cur_end_dt) {
        bodyFormData.append("EndDate", this.cur_end_dt);
      }
      if (this.cur_CustomerIDn) {
        bodyFormData.append("sCustomerIDn", this.cur_CustomerIDn);
      }
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      axios({
        method: "post",
        url: "/Api/Web/Inventory/Outbound/update",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              var outboundlist = rt.data.outbounds;
              that.outboundlist = null;
              that.outbound_total = rt.data.outbound_total;
              that.close_my_modal();
              that.$nextTick(() => {
                that.outboundlist = outboundlist;
              });
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    get_outbound_list_delay() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      var that = this;
      this.delay_timer = setTimeout(() => {
        that.get_outbound_list();
      }, 400);
    },
    async get_outbound_list() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }

      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (this.cur_start_dt) {
        bodyFormData.append("StartDate", this.cur_start_dt);
      }
      if (this.cur_end_dt) {
        bodyFormData.append("EndDate", this.cur_end_dt);
      }
      if (this.cur_CustomerIDn) {
        bodyFormData.append("CustomerIDn", this.cur_CustomerIDn);
      }
      if (this.cur_LocationID) {
        bodyFormData.append("LocationID", this.cur_LocationID);
      }
      if (this.cur_OutboundNo) {
        bodyFormData.append("OutboundNo", this.cur_OutboundNo);
      }
      if (this.cur_OutboundDate) {
        bodyFormData.append("OutboundDate", this.cur_OutboundDate);
      }
      if (this.cur_OrderNo_1) {
        bodyFormData.append("OrderNo_1", this.cur_OrderNo_1);
      }
      if (this.cur_OrderNo_2) {
        bodyFormData.append("OrderNo_2", this.cur_OrderNo_2);
      }
      if (this.cur_OutboundBy) {
        bodyFormData.append("OutboundBy", this.cur_OutboundBy);
      }
      if (this.cur_Status) {
        bodyFormData.append("Status", this.cur_Status);
      }
      if (this.cur_Remarks) {
        bodyFormData.append("Remarks", this.cur_Remarks);
      }
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Inventory/Outbound",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.outboundlist = rt.data.outbounds;
              that.brandlist = rt.data.brands;
              (that.storelist = rt.data.stores),
                (that.idxproductlist = rt.data.idxproducts),
                (that.customerlist = rt.data.customers),
                (that.usersidxlist = rt.data.users),
                (that.outbound_total = rt.data.outbound_total);
              that.per_page = rt.data.per_page;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            this.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      var today = new Date();
      this.today =
        today.getFullYear() +
        "-" +
        String(today.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(today.getDate()).padStart(2, "0");
      this.new_order.OutboundDate = this.today;

      if (!this.token || !this.user_id) {
        this.$router.push("/login");
        return;
      }
      return this.get_outbound_list();
    },
    table_load_sn() {
      this.$refs["selectSNTable"].clearSelection();
      let _tmp = JSON.parse(JSON.stringify(this.tmp_selected_sn));
      for (let i in _tmp) {
        for (let j in this.display_SN_array) {
          if (this.display_SN_array[j].SN == _tmp[i]) {
            this.$refs["selectSNTable"].toggleRowSelection(this.display_SN_array[j]);
          }
        }
      }
    },
    sn_shown(e) {
      if (this.cur_prod && this.cur_prod.Status == "Draft") {
        this.table_load_sn();
      }
    },
    selectSN(product) {
      this.cur_prod = product;
      this.input_SN = "";

      if(product.SystemSN){
        if(Array.isArray(product.SystemSN)) {
          this.display_SN_array = JSON.parse(JSON.stringify(product.SystemSN));
        } else {
          this.display_SN_array = JSON.parse(product.SystemSN);
        }
      } else {
        this.display_SN_array = [];
        alert("No S/N found for this product");
        return;
      }
      if(product.SerialNumber){
        if(Array.isArray(product.SerialNumber)){
          this.tmp_selected_sn = JSON.parse(JSON.stringify(product.SerialNumber));
        } else {
          this.tmp_selected_sn = JSON.parse(product.SerialNumber);
        }
      } else {
        this.tmp_selected_sn = [];
      }

      this.$refs["select-sn-modal"].show();
    },
    selectSNChange(val, row) {
      if (val.length > this.cur_prod.OrderQty) {
        this.$refs["selectSNTable"].toggleRowSelection(row);
        alert("You cannot add S/N more than the order quantity.");
      } else {
        this.tmp_selected_sn = [];
        for (let i in val) {
          this.tmp_selected_sn.push(val[i].SN);
        }
      }
    },
    selectSNAll(val) {
      if (val.length > this.cur_prod.OrderQty) {
        if (this.tmp_selected_sn.length == 0) {
          this.$refs["selectSNTable"].clearSelection();
          for (let i = 0; i <= (this.cur_prod.OrderQty - 1); i++) {
            this.$refs["selectSNTable"].toggleRowSelection(val[i]);
            this.tmp_selected_sn.push(val[i].SN);
          }
        } else {
          this.table_load_sn();
          alert("You cannot add S/N more than the order quantity.");
        }
      } else {
        this.tmp_selected_sn = [];
        for (let i in val) {
          this.tmp_selected_sn.push(val[i].SN);
        }
      }
    },
    searchSN() {
      if (this.tmp_selected_sn.includes(this.input_SN)) {
        alert("This S/N is already selected");
        return;
      }
      let table = this.$refs.selectSNTable;
      for (let i in this.display_SN_array) {
        let sn = this.display_SN_array[i].SN;
        if (sn == this.input_SN) {
          if (this.tmp_selected_sn.length >= this.cur_prod.OrderQty) {
            alert("You cannot add S/N more than the order quantity.");
          } else {
            table.toggleRowSelection(this.display_SN_array[i]);
            this.tmp_selected_sn.push(sn);
            this.input_SN = "";
          }
          return;
        }
      }
      alert("Input S/N does not exist");
    },
    submit_sn() {
      if(this.cur_prod.Status == 'Draft'){
        if (this.tmp_selected_sn){
          if(Array.isArray(this.tmp_selected_sn)){
            this.cur_prod.SerialNumber = JSON.parse(JSON.stringify(this.tmp_selected_sn));
          } else {
            this.cur_prod.SerialNumber = JSON.parse(this.tmp_selected_sn);
          }
        } else {
          this.cur_prod.SerialNumber = [];
        }
      }
      this.$refs["select-sn-modal"].hide();
    },
    close_select_sn_model() {
      this.$refs["select-sn-modal"].hide();
    },
    add_changed_sn_inv(inv) {
      this.changed_sn_inv[inv.InvIDn] = inv;
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
.search-button {
  margin-top: 2rem;
}
.select_text {
  margin-left: -1rem;
}
.card .table td {
  padding: 0.5rem;
}
.picimg {
  padding-left: 3rem;
  max-height: 6rem;
}
#outbound_modal .modal-title {
  font-size: 1.5rem;
}
#outbound_modal hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.el-collapse-item__header {
  background-color: #a5d1fa;
  font-size: 1.2rem;
  padding-left: 1rem;
}
.el-collapse-item__content {
  padding-bottom: 0.5rem;
}
.outbound_product_section {
  max-height: 12rem;
  overflow: auto;
}
.outbound_product_section .el-table td,
.el-table th {
  padding: 2px 0;
}
.product_row {
  border-top: 1px solid gray;
}
.summary_row {
  border-top: 1px solid gray;
  font-size: large;
}
.product_sn {
  max-width: 10rem;
  display: inline-flex;
}
.product_sn_col {
  max-height: 4rem;
  overflow: auto;
}
.form-group {
  margin-bottom: 0.5rem;
}
.input-sn {
  width: 15rem;
}
</style>
